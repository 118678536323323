import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Footer from '../components/footer'
import Toolbar from '../components/toolbar'

const NotFoundPage = () => (
  <Layout>
    <Toolbar/>
    <SEO title="404: Not found"/>
    <div className="center fillHeight">
      <h1>Page introuvable</h1>
      <p>Désolé mais la page demandé est introuvable.</p>
    </div>
    <Footer/>
  </Layout>
)

export default NotFoundPage
